<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <!-- <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div> -->
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>客户姓名/手机号</span>
              </template>
              <a-input v-model.trim="searchData.fuzzy" placeholder="客户姓名/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="上课校区" >
                      <a-select v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择" showSearch :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="课程名称" >
                      <a-select allowClear v-model="searchData.course_cate_id" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="授课老师" >
                      <a-select allowClear v-model="searchData.teacher_id" placeholder="请选择">
                        <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="所属班级" >
                      <a-select allowClear showSearch v-model="searchData.class_id" :filterOption="filterOption" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classes" :key="index" :value="d['class_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="上课的日期" >
                      <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" :allowClear="false" v-model="searchData.class_date" :placeholder="['开始时间', '结束时间']"/>
                    </a-form-item>
                    <a-form-item label="课程模式" >
                      <a-select v-model="searchData.is_deal" placeholder="请选择">
                        <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="备课状态" >
                      <a-select v-model="searchData.is_deal" placeholder="请选择">
                        <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '授课老师', width: '100px', dataIndex: 'realname', key: 'name' },
  { title: '手机号码', dataIndex: 'studio_id', key: '1' },
  { title: '排课数', dataIndex: 'distribute_id', key: '2' },
  { title: '已备课', dataIndex: 'course_cate', key: '3' },
  { title: '未备课', dataIndex: 'intention_level', key: '4' },
  { title: '已超时', dataIndex: 'channel_id', key: '5' },
]
		

			

import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import moment from 'moment'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      dealStatus: C_ITEMS.dealStatus,
      studios: [],
      classCategorys: [],
      teachers: [],
      classes: [],

      searchData: {},

      visible: false,
      authType:['education','prepare'],
    }
  },
  mixins: [ ranges , authority, tableMixins ],
  created() {
    this.getStudio()
    this.getClassCategory()
    this.getTeacher()
    this.getClasses()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.classCategorys = res.data
    },
    async getTeacher() {
      let res = await this.$store.dispatch('searchTeacherAction', {})
      this.teachers = res.data
    },
    async getClasses() {
      let res = await this.$store.dispatch('searchClassesAction', {})
      this.classes = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('analysisPrepareAction', { data: this.searchParams })
      this.list = res.data
      this.pageParams = res._meta
    },
  }
}
</script>
